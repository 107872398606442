import { Carousel } from '@mantine/carousel';
import { Text } from "@mantine/core";
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { useEffect, useState } from 'react';
import { DisplayProps } from '../../types/DisplayProps';
import CarouselSlideMixed from './CarouselSlideMixed';
import CarouselSlideImage from './CarouselSlideImage';


interface CarouselMixedProps {
    sourceURL: string,
}

export default function CarouselMixed({ sourceURL }: CarouselMixedProps) {
	const wheelGestures = WheelGesturesPlugin({
		forceWheelAxis: 'y',
	})

	const [displayData, setDisplayData] = useState<DisplayProps[]>([]);

	const getItems = () => {
		fetch(`https://cms.merlinrainer.de/items/${sourceURL}`)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			}).then((json) => {
				let data = json.data.map((item: any) => {
					const n = {} as DisplayProps;
					n.title = item.Titel;
					n.description = item.Beschreibung;
					n.medium = item.Medium;
					n.year = item.Jahr;
					n.imgPath = item.Bild !== null ? "https://cms.merlinrainer.de/assets/" + item.Bild : "";
					n.index = item.Index;
					n.videoLink = item.VideoID !== null ? "https://www.youtube.com/embed/" + item.VideoID : "";
					return n
				})
				data.sort((a: DisplayProps, b: DisplayProps) => (a.index > b.index) ? 1 : -1)
				setDisplayData(data)
			}).catch((error) => {
				console.error('There has been a problem with your fetch operation:', error);
			})
	};


	useEffect(() => {
		getItems();
	}, []);


	const mainItems = displayData.map(item => {
		if (item.videoLink !== "") {
			return <CarouselSlideMixed videoLink={item.videoLink} description={item.description} title={item.title} />
		}
		return  <CarouselSlideMixed imgPath={item.imgPath} description={item.description} title={item.title} />
	});


	return (
		<div style={{ height: "100%", display: 'flex' }}>
			<Carousel
				mt={'5vh'}
				withControls={false}
				height="100%"
				sx={{ flex: 1 }}
				plugins={[wheelGestures]}
				dragFree
				loop
				slideSize="55%"
				slideGap="xl"
				breakpoints={[
					{ maxWidth: 'md', slideSize: "100%" }
				]}
			>
				{mainItems.length > 0 ? mainItems.map((item) => <Carousel.Slide>{item}</Carousel.Slide>) : <Carousel.Slide><Text>Loading...</Text></Carousel.Slide>}
			</Carousel>
		</div>
	)
}