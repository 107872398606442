import { AspectRatio, Grid, Text, Image } from "@mantine/core";

interface CarouselSlideMixedProps {
	imgPath?: string
	videoLink?: string
	title: string
	description: string
}

export default function CarouselSlideMixed({ imgPath, videoLink, title, description }: CarouselSlideMixedProps) {
	return (
		<Grid mt={'6vh'} mx={"2vw"} align="center">
			<Grid.Col span={12}>
				{imgPath ? 
				<Image height={'52vh'} fit='contain' src={imgPath} /> :
				<AspectRatio ratio={1920 / 1080} sx={{ maxWidth: "100%" }} mx="auto">
					<iframe
						src={videoLink}
						allowFullScreen
						title={title}
						width={"100%"}
						height={"400vh"}
					/>{" "}
				</AspectRatio>}
			</Grid.Col>
			<Grid.Col span={12} >
				<Text weight="bold" >{title}</Text>
				<Text>{description}</Text>
			</Grid.Col>
		</Grid>
	)
}