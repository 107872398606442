import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Grafik from './components/Grafik';
import Malerei from './components/Malerei';
import Performance from './components/Performance';
import Home from './components/Home';
import Illustration from './components/Illustration';
import Vita from './components/Vita';
import Contact from './components/Contact';
import Impressum from './components/Impressum';
import Exhibition from './components/Exhibition';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App main={<Home/>} />} />
      <Route path="malerei" element={<App main={<Malerei/>} />}/>
      <Route path="grafik" element={<App main={<Grafik/>} />}/>
      <Route path="illustration" element={<App main={<Illustration/>} />} />
      <Route path="performance" element={<App main={<Performance/>} />} />
      <Route path="ausstellungen" element={<App main={<Exhibition/>} />} />
      <Route path="vita" element={<App main={<Vita/>} />} />
      <Route path="contact" element={<App main={<Contact/>} />} />
      <Route path="impressum" element={<App main={<Impressum/>} />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
