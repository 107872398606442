import { Center, Image } from "@mantine/core";
import { useEffect, useState } from "react";

export default function Home() {
	let [imageUrl, setImageUrl] = useState("");

	const getHomeImage = () => {
		fetch('https://cms.merlinrainer.de/items/Home')
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			}).then((json) => {
				setImageUrl("https://cms.merlinrainer.de/assets/" + json.data.Bild);
			}).catch((error) => {
				console.error('There has been a problem with your fetch operation:', error);
			})
	};

	useEffect(() => {
		getHomeImage();
	}, []);

	return (
		<Center>
			<Image height={'80vh'} fit='contain' src={imageUrl} />
		</Center>
	)
}