import { Image, Grid, Text, MediaQuery } from "@mantine/core";
import { useState } from "react";
import { DisplayProps } from "../../types/DisplayProps"
import ImageModal from "./ImageModal";

export default function CarouselSlideImage({ imgPath, title, description, medium, year, size }: DisplayProps) {
	const [opened, setOpened] = useState(false);

	return (
		<>
			<ImageModal opened={opened} setOpened={setOpened} imgPath={imgPath} title={title} description={description} year={year} medium={medium} size={size} />

			<Grid mt={'2vmin'} px={"2vw"}>
				<Grid.Col span={12}>
					<Image height={'52vh'} fit='contain' src={imgPath} onClick={() => setOpened(true)} />
				</Grid.Col>

				<MediaQuery largerThan="md" styles={{ display: 'none' }}>
					<Grid.Col span={12} style={{ textAlign: 'right' }}>
						<Text>{year}</Text>
						<Text>{title}</Text>
						<Text>{medium}</Text>
						<Text>{size}</Text>
					</Grid.Col>
				</MediaQuery>

				<MediaQuery smallerThan="md" styles={{ display: 'none' }}>
					<Grid.Col span={12} style={{ textAlign: 'center' }} pt={"6vh"} >
						<Text>{year}</Text>
						<Text>{title}</Text>
						<Text>{medium}</Text>
						<Text>{size}</Text>
					</Grid.Col>
				</MediaQuery>

			</Grid>
		</>
	)
}