import { Carousel } from "@mantine/carousel"
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"
import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { DisplayProps } from "../../types/DisplayProps";
import CarouselSlideImage from "./CarouselSlideImage";

interface CarouselImageProps {
	sourceCollection: string,
}

export default function CarouselImage({ sourceCollection }: CarouselImageProps) {
	const wheelGestures = WheelGesturesPlugin({
		forceWheelAxis: 'y',
	})

	const [imageData, setImageData] = useState<DisplayProps[]>([]);

	const getImages = () => {
		fetch('https://cms.merlinrainer.de/items/' + sourceCollection)
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			}).then((json) => {
				let data = json.data.map((item: any) => {
					const n = {} as DisplayProps;
					n.title = item.Titel;
					n.description = item.Beschreibung;
					n.medium = item.Medium;
					n.year = item.Jahr;
					n.imgPath = "https://cms.merlinrainer.de/assets/" + item.Bild;
					n.index = item.Index;
					n.size = item.Abmessung;
					return n
				})
				data.sort((a: DisplayProps, b: DisplayProps) => (a.index > b.index) ? 1 : -1)
				setImageData(data)

			}).catch((error) => {
				console.error('There has been a problem with your fetch operation:', error);
			})
	};

	useEffect(() => {
		getImages();
	}, []);

	const imageSlides = imageData.map((item) => {
		return (<CarouselSlideImage
			imgPath={item.imgPath}
			description={item.description}
			title={item.title}
			medium={item.medium}
			year={item.year}
			index={item.index}
			videoLink={""}
			size={item.size} />)
	});

	return (
		<div style={{ height: "100%", display: 'flex' }}>
			<Carousel
				mt={'2vh'}
				withControls={false}
				height="100%"
				sx={{ flex: 1 }}
				plugins={[wheelGestures]}
				dragFree
				loop
				slideSize="45%"
				slideGap='xl'
				breakpoints={[
					{ maxWidth: 'md', slideSize: "100%" }
				]}
			>
				{imageSlides.length > 0 ?
					imageSlides.map((item, index) => <Carousel.Slide key={index}>{item}</Carousel.Slide>)
					:
					<Carousel.Slide><Text>Loading...</Text></Carousel.Slide>}
			</Carousel>
		</div>
	)
}