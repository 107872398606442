import { Modal, Image, Grid, MediaQuery } from "@mantine/core";

interface ImageModalProps {
	opened: boolean
	setOpened: (v: boolean) => void
	imgPath: string
	title: string
	description: string
	year: string
	medium: string
	size: string
}

export default function ImageModal({ opened, setOpened, imgPath, title, description, year, medium, size }: ImageModalProps) {
	return (

		<>
			<MediaQuery largerThan="md" styles={{ display: 'none' }}>
				<Modal
					opened={opened}
					onClose={() => setOpened(false)}
					centered
					fullScreen
					radius={0}
				>
					<Image src={imgPath} fit='contain'/>
					{/* <Space h="md" />
					<Text weight="bold">{title}</Text>
					<Text>{year}</Text>
					<Text>{medium}</Text>
					<Text>{size}</Text>
					<Text>{description}</Text> */}
				</Modal>
			</MediaQuery>

			<MediaQuery smallerThan="md" styles={{ display: 'none' }}>
				<Modal
					opened={opened}
					onClose={() => setOpened(false)}
					centered
					size={"80vw"}
					radius={0}
					overlayOpacity={0.5}
					withCloseButton={false}
				>
					<Grid justify='center' align="flex-end" grow>
						<Grid.Col span={6}>
							<Image height={"80vh"} src={imgPath} fit='contain' />
						</Grid.Col>
						{/* <Grid.Col span={1}>
							<Text weight="bold">{title}</Text>
							<Text>{year}</Text>
							<Text>{medium}</Text>
							<Text>{size}</Text>
							<Text>{description}</Text>
						</Grid.Col> */}
					</Grid>
				</Modal>
			</MediaQuery>
		</>
	)
}