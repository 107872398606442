import { Button, Center, Group, Modal, Notification, Space } from "@mantine/core";
import { useEffect, useRef, useState } from "react";

export default function EntryModal() {
	let [entryVideoURL, setEntryVideoURL] = useState("");

	const getEntryVideoURL = () => {
		fetch('https://cms.merlinrainer.de/items/Home')
			.then((response) => {
				if (response.ok) {
					return response.json();
				}
			}).then((json) => {
				setEntryVideoURL("https://cms.merlinrainer.de/assets/" + json.data.Eingangsanimation + ".mp4#t=0.001");
			}).catch((error) => {
				console.error('There has been a problem with your fetch operation:', error);
			})
	};

	useEffect(() => {
		getEntryVideoURL();
	}, []);
	
	const [videoOpened, setVideoOpened] = useState(true);
	const [cookiesOpened, setCookiesOpened] = useState(true)
	const [cookiesAccepted, setCookiesAccepted] = useState(true)

	const handleCookieNotice = (accepted: boolean) => {
		setCookiesOpened(false);
		setCookiesAccepted(accepted);
	}

	const entryVideo = useRef<HTMLVideoElement>(null);

	const handleEnter = () => {
		if (!cookiesAccepted) return;
		if (entryVideo.current) entryVideo.current.play();
		setCookiesOpened(false);
		setTimeout(() => {
			setVideoOpened(false);
		}, 4000);
	}

	return (
		<>
			<Modal
				style={{ zIndex: 997 }}
				opened={videoOpened}
				onClose={() => setVideoOpened(false)}
				fullScreen
				withCloseButton={false}
				padding={0}
				transitionDuration={400}
			>
				<Center style={{ height: "100vh", cursor: "pointer", zIndex: 998 }} >
					<video ref={entryVideo} src={entryVideoURL} muted height={"100%"} onClick={() => handleEnter()} />
					<Notification
						title="Cookies Notice"
						color="dark"
						radius={0}
						disallowClose
						style={{ zIndex: 999, maxWidth: "400px", position: "absolute", bottom: "10px", display: cookiesOpened ? "block" : "none" }}
					>
						<Space h="xs" />
						By entering this site you accept that there are cookies being set (coming from embedded YouTube videos).
						<Space h="xs" />
						Mit dem Betreten der Seite akzeptieren Sie das Cookies gesetzt werden (durch eingebettete YouTube Videos).
						<Space h="md" />
						<Group position="apart">
							<Button onClick={() => handleCookieNotice(true)} variant="outline" color="dark">Enter</Button>
							<Button onClick={() => handleCookieNotice(false)} variant="outline" color="dark">Decline</Button>
						</Group>
					</Notification>
				</Center>
			</Modal >

		</>
	)
}