import { Container } from "@mantine/core";
import { useEffect, useState } from "react";

interface TextDisplayProps {
	sourceURL: string,
}

export default function DisplayText({ sourceURL }: TextDisplayProps) {
	const [textData, setTextData] = useState("Loading...");

	const getText = () => {
		fetch(`https://cms.merlinrainer.de/items/${sourceURL}`)
		.then((response) => {
			if (response.ok) {
				return response.json();
			}
		}).then((json) => {
			setTextData(json.data.Text)
		}).catch((error) => {
			console.error('There has been a problem with your fetch operation:', error);
		})
	};

	useEffect(() => {
		getText();
	}, []);
	
	return (
		<Container>
			<div dangerouslySetInnerHTML={{__html: textData}} />
		</Container>
	)
}