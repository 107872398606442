import { useState } from "react";
import {
  createStyles,
  MantineProvider,
  AppShell,
  Header,
  Navbar,
  Burger,
  MediaQuery,
  Anchor,
  Footer,
  Button,
  Group,
  NavLink,
  Text,
  Image,
  Space,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import EntryModal from "./components/EntryModal";
import { CustomFonts } from "./fonts/CustomFont";

interface AppProps {
  main: JSX.Element;
}

const useStyles = createStyles((theme) => ({
  navbar: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));

export default function App({ main }: AppProps) {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(-1);

  const sites = [
    { label: "Malerei", link: "/malerei" },
    { label: "Grafik", link: "/grafik" },
    { label: "Illustration", link: "/illustration" },
    { label: "Performance", link: "/performance" },
    { label: "Ausstellungen", link: "/ausstellungen" },
    { label: "Vita", link: "/vita" },
  ];

  const location = useLocation();

  const navItems = sites.map((item, index) => (
    <div key={index}>
      <Space w="sm" />
      <Anchor
        component={Link}
        to={item.link}
        style={{ textDecoration: "none" }}
      >
        <NavLink
          active={index === active || location.pathname === item.link}
          label={
            <Text weight={700} size="md">
              {item.label}
            </Text>
          }
          onClick={() => {
            if (opened) setOpened((o) => !o);
            setActive(index);
          }}
          color="gray"
          variant="subtle"
        />
      </Anchor>
    </div>
  ));

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontFamily: "Palatino",
      }}
    >
      <CustomFonts />
      <EntryModal />
      <AppShell
        padding={0}
        navbarOffsetBreakpoint="sm"
        header={
          <Header height={70} withBorder={false} pt="md">
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Group position="apart" px="md">
                <Anchor
                  component={Link}
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  onClick={() => setActive(-1)}
                >
                  <Image
                    radius="xs"
                    src="logo.png"
                    alt="Merlin Rainer Logo"
                    style={{ maxWidth: "20vw" }}
                  />
                </Anchor>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  mr="xl"
                />
              </Group>
            </MediaQuery>
            <div className={classes.links}>
              <Group position="left" spacing="lg" ml={"2vmin"}>
                <Anchor
                  component={Link}
                  to={"/"}
                  style={{ textDecoration: "none" }}
                  onClick={() => setActive(-1)}
                >
                  <Image
                    radius="xs"
                    src="logo.png"
                    alt="Merlin Rainer Logo"
                    height={"7vmin"}
                    style={{ maxWidth: "10vmin" }}
                  />
                </Anchor>
                {navItems}
              </Group>
            </div>
          </Header>
        }
        navbar={
          <Navbar
            className={classes.navbar}
            width={{ base: "100%" }}
            height={250}
            p="md"
            hidden={!opened}
            style={{ position: "absolute" }}
          >
            {navItems}
          </Navbar>
        }
        footer={
          <Footer height={60} p="md" withBorder={false} fixed>
            <Group position="center" spacing="lg">
              <Anchor component={Link} to="/contact">
                <Button
                  variant="subtle"
                  color="gray"
                  onClick={() => {
                    setActive(-1);
                    setOpened(false);
                  }}
                >
                  contact
                </Button>
              </Anchor>
              <Anchor component={Link} to="/impressum">
                <Button
                  variant="subtle"
                  color="gray"
                  onClick={() => {
                    setActive(-1);
                    setOpened(false);
                  }}
                >
                  impressum
                </Button>
              </Anchor>
            </Group>
          </Footer>
        }
      >
        {main}
      </AppShell>
    </MantineProvider>
  );
}
